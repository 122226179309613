<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="Omtaler" />
    </b-form-group>
    <b-form-group label="Description">
      <b-form-textarea v-model="content.description" />
    </b-form-group>
    <b-form-group label="Limit">
      <b-input v-model="content.limit" placeholder="12" />
    </b-form-group>
    <b-form-group label="Same or higher rating than:">
      <b-input v-model="content.rating" placeholder="3" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'PagesReviewSlideshowWidget',
  props: { value: { type: Object } },
  data() {
    return { content: this.value, resolved: { type: Array }, toplistOptions: [], numberOfCategories: 4 }
  },
  async mounted() {
    this.resolved = this.$attrs.resolved
  },
  methods: {},
}
</script>
