<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Pages <small>all listings.</small></h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button variant="default" :to="{ name: 'pages.new' }" :disabled="$cannot('pages.manage')">
          New Page
        </b-button>
      </b-col>
    </b-row>

    <!-- page content -->
    <b-card>
      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(options)="row">
          <b-dropdown variant="default" size="sm" text="Options" offset="25" right>
            <b-dropdown-item
              size="sm"
              :to="{ name: 'pages.edit', params: { id: row.item.id } }"
              :disabled="$cannot('pages.manage')"
            >
              Edit
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!row.item.is_default"
              v-b-modal="`page-${row.item.id}`"
              size="sm"
              :disabled="$cannot('pages.manage')"
            >
              Delete
            </b-dropdown-item>

            <b-modal :id="`page-${row.item.id}`" title="Delete Page" @ok="deletePage(row.item.id)">
              Are you sure you want to delete this page?
            </b-modal>
          </b-dropdown>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'PagesPage',
  inject: ['$cannot'],
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  data() {
    return {
      listing: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', sortable: false, label: 'ID' },
        { key: 'name', sortable: false },
        { key: 'slug', sortable: false },
        { key: 'options', label: '', sortable: false },
      ]
    },
  },
  methods: {
    async loadContent() {
      const page = this.$route.query.page ?? 1
      const response = await this.$store.dispatch('pages/listing', page)

      this.$nextTick(() => {
        this.listing = response.data()
        this.meta = response.meta
      })
    },
    paginationChange(page) {
      this.$router.push(page === 1 ? { name: 'pages' } : { name: 'pages', query: { page } })

      this.loadContent()
    },
    paginationLinkGenerator(page) {
      return page === 1 ? { name: 'pages' } : { name: 'pages', query: { page } }
    },
    async deletePage(id) {
      await this.$store.dispatch('pages/delete', id)

      this.loadContent()
    },
  },
}
</script>
