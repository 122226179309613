<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Slik fungerer YouWish.no" />
    </b-form-group>
    <img class="img-fluid" src="/widgets/onetwothree.jpg" />
  </div>
</template>

<script>
export default {
  name: 'PagesOneTwoThreeWidget',
  props: { value: { type: Object } },
  data() {
    return { content: this.value }
  },
}
</script>
