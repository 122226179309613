<template>
  <div>
    <froala id="edit" tag="textarea" :config="config" v-model="content.content" />
    <b-modal title="Pick image">
      <image-browser />
    </b-modal>
  </div>
</template>

<script>
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'

import ImageBrowser from '../../../components/ImageBrowser'

export default {
  name: 'PagesTextWidget',
  components: { ImageBrowser },
  props: { value: { type: Object } },
  data() {
    return {
      content: this.value,
      config: {
        toolbarButtons: [
          'insertImage',
          '|',
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'clearFormatting',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
      },
    }
  },
}
</script>
