<template>
  <b-row align-v="center">
    <b-col>
      <h5>SEO block</h5>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'PagesDynamicSeoBlockWidget',

  props: {
    value: { type: String },
    resolved: { type: String, required: false }
  },
}
</script>
