<template>
  <b-overlay :show="loading">
    <input
      class="invisible"
      accept="image/*"
      ref="file"
      type="file"
      style="width: 0; height: 0"
      @change="upload"
      multiple
    />
    <b-button variant="outline-primary" @click="pick"> Upload to {{ prefix }} </b-button>
  </b-overlay>
</template>

<script>
import { media } from '@/api'

export default {
  name: 'ImageBrowserFileInput',
  props: { prefix: { type: String, required: true } },
  data() {
    return { loading: false, files: [] }
  },
  methods: {
    pick() {
      this.$refs.file.click()
    },
    async upload(e) {
      await media.create(this.prefix, Array.from(e.target.files))
      this.$bvToast.toast(`${e.target.files.length} image(s) were uploaded to: "${this.prefix}" namespace.`, {
        title: 'Image uploaded.',
        variant: 'primary',
      })
      this.$emit('upload', this.prefix)
    },
  },
}
</script>
