<template>
  <b-form-group label="Title">
    <b-input v-model="content.title" placeholder="e.g Newsletter" />
  </b-form-group>
</template>

<script>
export default {
  name: 'PagesNewsletterWidget',
  props: { value: { type: Object } },
  data() {
    return { content: this.value }
  },
}
</script>
