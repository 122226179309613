<template>
  <b-row align-v="center">
    <b-col>
      <h5>Randomly assigned toplist (from all matching that are generated on Toplists page)</h5>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'PagesDynamicToplistWidget',

  props: {
    value: { type: String },
    resolved: { type: Object, required: false }
  },
}
</script>
