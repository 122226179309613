<template>
  <b-row>
    <b-col>
      <b-form-group label="Title">
        <b-input v-model="content.title" placeholder="e.g Say hello" />
      </b-form-group>
    </b-col>
    <b-col cols="4">
      <b-form-group label="Count">
        <b-form-spinbutton v-model="content.count" min="4" max="16" />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'PagesRelatedProductsWidget',
  props: { value: { type: Object } },
  data() {
    return { content: this.value }
  },
}
</script>
