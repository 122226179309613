<template>
  <img class="img-fluid" src="/widgets/opengiftcard.jpg" />
</template>

<script>
export default {
  name: 'PagesStaticGiftcardWidget',
  props: { value: { type: String } },
  data() {
    return { content: 'static' }
  },
}
</script>
