<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Say hello" />
    </b-form-group>
    <b-form-group label="Text">
      <froala id="edit" tag="textarea" v-model="content.text" />
    </b-form-group>

    <b-form-group label="Button text">
      <b-input v-model="content.btn_text" placeholder="e.g Say hello" />
    </b-form-group>
    <b-form-group label="Button link">
      <b-input v-model="content.btn_link" placeholder="e.g /opplevelser/olsmaking" />
    </b-form-group>
    <b-form-group label="Image Left or Right">
      <b-form-select v-model="content.image_position" :options="options"></b-form-select>
    </b-form-group>
    <b-form-group label="Select Image">
      <pages-image-widget v-model="content.image" :resolved="resolved" />
    </b-form-group>
  </div>
</template>

<script>
import PagesImageWidget from './PagesImageWidget.vue'
export default {
  components: { PagesImageWidget },
  name: 'PagesStaticTwoColWidget',
  props: { value: { type: Object }, resolved: { type: Object } },
  data() {
    return {
      content: this.value,
      options: [
        { value: 'left', text: 'Left' },
        { value: 'right', text: 'Right' },
      ],
    }
  },
}
</script>
