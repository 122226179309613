<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col class="mb-3" cols="4" v-for="(item, i) in list" :key="i">
        <b-img
          :src="item.url"
          :alt="item.file_name"
          :class="{ selected: selectedHasItem(item) }"
          @click="toggleSelect(item)"
          thumbnail
          fluid
        />
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col cols="auto">
        <b-pagination
          v-if="meta.total > meta.per_page"
          v-model="meta.current_page"
          :total-rows="meta.total"
          :per-page="meta.per_page"
          @change="loadContent"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { media } from '@/api'

export default {
  name: 'ImageBrowserList',
  props: { filter: { type: String, required: true }, multiple: { type: Boolean, default: false } },
  data() {
    return {
      list: [],
      loading: true,
      page: 1,
      selected: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  mounted() {
    this.loadContent()
  },
  methods: {
    async loadContent(page) {
      this.page = page
      const response = await media.go(this.page, { filter: { prefix: this.filter } })
      this.list = response.data()
      this.meta = response.meta
      this.loading = false
    },
    refresh() {
      this.loadContent(this.list.length >= this.meta.per_page ? (this.page += 1) : this.page)
    },
    selectedHasItem(item) {
      return this.selected.find(some => some.id === item.id)
    },
    toggleSelect(item) {
      if (!this.multiple) {
        this.selected = [item]

        return this.$emit('select', [item])
      }

      if (this.selectedHasItem(item)) {
        const index = this.selected.findIndex(find => find.id === item.id)
        this.selected.splice(index, 1)
      } else {
        this.selected.push(item)
      }

      this.$emit('select', this.selected)
    },
  },
}
</script>

<style lang="scss" scoped>
.selected {
  background: cyan;
}
</style>
