<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Gavetips" />
    </b-form-group>
    <b-form-group label="Link Text">
      <b-input v-model="content.link_text" placeholder="e.g Se flere" />
    </b-form-group>
    <b-form-group label="Link URL">
      <b-input v-model="content.link_url" placeholder="e.g /gavetips" />
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="content.is_news">News</b-form-checkbox>
      <b-form-checkbox v-model="content.header">Header</b-form-checkbox>
    </b-form-group>
    <b-row align-v="center">
      <b-col>
        <b-form-group label="Category">
          <validation-provider name="Toplist" v-slot="context" rules="required">
            <multi-select
              v-model="resolved"
              placeholder="Pick a category"
              track-by="id"
              label="name"
              :disabled="content.random"
              :options="toplistOptions"
              @open="findToplist"
              @select="selectToplist"
            />

            <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
              {{ context.errors[0] }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { base } from '@/api'

export default {
  name: 'PagesCategoryToplistWidget',
  components: { MultiSelect },
  props: { value: { type: Object } },
  data() {
    return { content: this.value, resolved: { type: Object }, toplistOptions: [] }
  },
  async mounted() {
    await this.findToplist()
    this.resolved = this.toplistOptions.find(s => s.id === this.value.id)
  },
  methods: {
    async findToplist() {
      const response = await base.all()

      this.toplistOptions = response.data.categories
    },
    selectToplist(selected) {
      this.$set(this.content, 'id', selected.id)
    },
  },
}
</script>
