<template>
  <b-row align-v="center">
    <b-col>
      <h4 v-if="content.random">Random Toplist Selected</h4>
      <validation-provider v-else name="Toplist" v-slot="context" rules="required">
        <multi-select
          v-model="resolved"
          placeholder="Pick a toplist"
          track-by="title"
          label="title"
          :disabled="content.random"
          :options="toplistOptions"
          @open="findToplist"
          @select="selectToplist"
        />

        <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
          {{ context.errors[0] }}
        </small>
      </validation-provider>
    </b-col>
    <b-col cols="auto">
      <b-form-checkbox v-model="content.random" button :button-variant="`${content.random ? 'default' : 'primary'}`">
        {{ content.random ? 'Pick a Toplist' : 'Random Toplist' }}
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { toplists } from '@/api'

export default {
  name: 'PagesToplistWidget',
  components: { MultiSelect },
  props: { value: { type: Object } },
  data() {
    return { content: this.value, resolved: { type: Object }, toplistOptions: [] }
  },
  async mounted() {
    await this.findToplist()
    this.resolved = this.toplistOptions.find(s => s.id === this.value.id)
  },
  methods: {
    async findToplist() {
      const response = await toplists.all()

      this.toplistOptions = await response.data()
    },
    selectToplist(selected) {
      this.$set(this.content, 'id', selected.id)
    },
  },
}
</script>
