<template>
  <div>
    <b-form-group label="Title">
      <b-input v-model="content.title" placeholder="e.g Steder" />
    </b-form-group>
    <b-form-group label="Link Text">
      <b-input v-model="content.link_text" placeholder="e.g Se flere steder" />
    </b-form-group>
    <b-form-group label="Link URL">
      <b-input v-model="content.link_url" placeholder="e.g /steder" />
    </b-form-group>
    <b-row align-v="center">
      <b-col>
        <b-form-group label="Places">
          <validation-provider name="Toplist" v-slot="context" rules="required">
            <multi-select
              v-model="resolved"
              placeholder="Pick a place"
              track-by="id"
              label="name"
              :disabled="content.random"
              :options="toplistOptions"
              @select="selectToplist"
              @remove="selectToplist"
              :multiple="true"
            />

            <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
              {{ context.errors[0] }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { location } from '@/api'

export default {
  name: 'PagesFeaturedPlacessWidget',
  components: { MultiSelect },
  props: { value: { type: Object } },
  data() {
    return {
      content: this.value,
      options: [
        { value: 'single', text: 'Single' },
        { value: 'multiple', text: 'Multiple' },
      ],
      resolved: { type: Array },
      toplistOptions: [],
      numberOfCategories: 4,
    }
  },
  mounted() {
    location.cities.all().then(({data}) => {
      this.toplistOptions = data
      this.resolved = this.toplistOptions.filter(option => this.value.items.includes(option.id))
    })
  },

  methods: {
    selectToplist(selected) {
      let arr = this.content.items
      if (this.content.items.includes(selected.id)) {
        arr = arr.filter(i => i !== selected.id)
      } else {
        arr.push(selected.id)
      }
      this.$set(this.content, 'items', arr)
    },
  },
}
</script>
