<template>
  <div>
    <b-overlay :show="loading">
      <b-tabs pills vertical>
        <b-tab v-for="(item, i) in prefixes" :key="i" :title="item">
          <image-browser-list :ref="item" :filter="item" :multiple="multiple" @select="handleSelect" />
        </b-tab>
        <template #empty>
          <div class="text-center text-muted">
            There's no file in the media library<br />
            Upload your first file using the <b>upload</b> button below.
          </div>

          <b-row align-h="center" class="my-3">
            <b-col cols="auto">
              <image-browser-file-input class="my-4" :prefix="prefix" @upload="handleUpload" />
            </b-col>
          </b-row>
        </template>
      </b-tabs>

      <template v-slot:overlay>
        Loading...
      </template>
    </b-overlay>

    <template v-if="prefixes.length">
      <hr />
      <b-row align-h="between">
        <b-col cols="auto">
          <image-browser-file-input :prefix="prefix" @upload="handleUpload" />
        </b-col>
        <b-col v-if="selected.length" cols="auto">
          <b-button variant="primary" @click="emitSelected"> Pick selected ({{ selected.length }}) </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { media } from '@/api'
import ImageBrowserList from './ImageBrowserList.vue'
import ImageBrowserFileInput from './ImageBrowserFileInput.vue'

export default {
  name: 'ImageBrowser',
  components: { ImageBrowserList, ImageBrowserFileInput },
  props: { prefix: { type: String, default: 'center' }, multiple: { type: Boolean, default: false } },
  data() {
    return { loading: true, prefixes: [], selected: [] }
  },
  mounted() {
    this.loadContent()
  },
  methods: {
    async loadContent() {
      this.prefixes = (await media.prefixes()).data
      this.loading = false
    },
    handleUpload(prefix) {
      this.loadContent()
      this.$refs[['page'].includes(prefix) ? "" : prefix][0].refresh()
    },
    handleSelect(selected) {
      this.selected = selected
    },
    emitSelected() {
      this.$emit('select', this.multiple ? this.selected : this.selected[0])
    },
  },
}
</script>
